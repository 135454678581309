.input {
  @apply block w-full focus:ring-1 text-primary-grey-dark rounded-lg pr-3.5 border-gray-300 disabled:border-gray-200 focus:border-amplify-green-500 focus:ring-fresh-neon-100 disabled:bg-gray-50 disabled:cursor-not-allowed;
}

.input-ring {
  @apply focus:ring-1 focus:ring-fresh-neon-100;
}

.input-ring-none {
  @apply focus:ring-0;
}

.input-error {
  @apply border-red-300 focus:border-red-300 focus:ring-red-100 pr-11;
}
